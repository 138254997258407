<!-- <app-toolbar></app-toolbar> -->
<router-outlet></router-outlet>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  [zIndex]="9999"
  [fullScreen]="true">
</ngx-spinner>
<!-- [zIndex]="1059" -->
<!-- template="<img src='../assets/images/logo-small-black.png' />" -->
<!-- template="<img src='https://media.giphy.com/media/o8igknyuKs6aY/giphy.gif' />" -->
