import { Register, RegisterApi } from '@models/authentication/Register';

export const toRegisterApi = (x: Register): RegisterApi => ({
  nickname: x.nickname,
  first_name: x.firstName,
  second_name: x.secondName,
  password: x.password,
  email: x.email,
  date_of_birth: x.birthday,
  gender_id: x.genderId,
  type_user_id: x.typeUserId,
  terms: x.terms ? 1 : 0,
});