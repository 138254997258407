import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
// LIBS
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
// SERVICES
import { AuthenticationService } from '@services/authentication/authentication.service';
import { AlertService } from '@services/notification/alert.service';
import { SpinnerService } from '@services/spinner/spinner.service';
// MODELS
import { UserLogin } from '@models/authentication/UserLogin';
import { ResponseApi } from '@models/response-api/ResponseApi';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  form!: FormGroup;
  hide = true;
  type = "password";

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SignInComponent>,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private spinner: SpinnerService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      nickname: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }

  get formControls() { return this.form.controls; }

  toggleVisibility($event: any) {
    $event.preventDefault();
    this.hide = !this.hide;
    this.type = this.hide ? "password" : "text";
  }

  onSubmit($event: Event) {
    $event.stopPropagation();
    this.authenticate();
  }

  authenticate() {
    this.spinner.show();
    this.subscription.add(this.authenticationService.authenticate(this.form.value as UserLogin)
    .subscribe({
      next: ({ success, data, message }) => {
        if (success) {
          this.alertService.showSmallSuccess(message);
          this.authenticationService.doLoginUser(data);
          this.dialogRef.close();
        } else {
          this.alertService.showSmallErrorServer(message);
        }
      },
      error: (error: HttpErrorResponse) => {
        const errorApi: ResponseApi = error.error as ResponseApi;
        if (error.status === 401 && !errorApi.success) {
          this.alertService.showError(errorApi.message);
        } else {
          this.alertService.showSmallErrorServer(error.error);
        }
      }}).add(() => this.spinner.hide()));
  }

}
