import { Injectable } from '@angular/core';
// SERVICIOS
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  requests = 0;
  completed = 0;

  constructor(private spinner: NgxSpinnerService) {}

  get existSpinnerActivated() {
    return this.requests > this.completed;
  }

  get complete() {
    return this.requests > 0 && this.requests === this.completed;
  }

  show() {
    this.requests++;
    this.validate();
  }

  hide() {
    this.completed++;
    this.validate();
  }

  validate() {
    this.complete ? this.hidden() : this.spinner.show();
  }

  hidden() {
    setTimeout(() => {
      if (!this.existSpinnerActivated) {
        this.spinner.hide();
        this.requests = 0;
        this.completed = 0;
      }
    }, 1000);
  }
}
