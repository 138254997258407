// ANGULAR
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
// RXJS
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
// SERVICES
import { AuthenticationService } from '@services/authentication/authentication.service';
import { MatDialog } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService, private dialog: MatDialog) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        const error = {...err, message: err?.error?.message || err.message };
        console.log(err, 'error interceptro');
        if (err.status === 401) {
          this.dialog.closeAll();
          this.authService.logout();
        }
        return throwError(error);
      })
    );
  }
}