<mat-icon class="btn-close-dialog" (click)="closeDialog()">close</mat-icon>
<div class="wrapper">
  <h2 class="display-2 text-center text-light">
    {{'ROOM.FORM.TITLE' | translate}}
  </h2>
  <div class="profile-content" mat-dialog-content>
    <form [formGroup]="form" id="form" #ngForm class="form-kama" (ngSubmit)="initTransaction($event)">
      <div class="my-4 row">
        <div class="col">
          <button mat-flat-button type="button" class="btn-outline">
            {{'ROOM.FORM.RECOVER_ACCOUNT' | translate}}
          </button>
        </div>
      </div>

      <div class="row flex-column-reverse flex-lg-row">
        <div class="col-lg-9">
          <div class="mb-2 row">
            <label for="categorie" class="col col-form-label">
              {{'ROOM.FORM.CATEGORY' | translate}}
            </label>
            <div class="col-sm-9 col-lg-8">
              <ng-select [items]="typesKamas"
                [multiple]="true"
                class="form-control form-control-sm"
                bindLabel="name"
                bindValue="value"
                formControlName="typeKamaId"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label ps-2">{{item.name}}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="mb-2 row">
            <label for="nameKAMA" class="col col-form-label">
              {{'ROOM.FORM.NAME_KAMA' | translate}}
            </label>
            <div class="col-sm-9 col-lg-8">
              <input type="text" formControlName="nameKAMA" class="form-control form-control-sm" id="nameKAMA"
                [ngClass]="{'is-invalid': formControls['nameKAMA'].getError('required') && formSubmitted}">
            </div>
          </div>

          <div class="mb-2 row">
            <label for="typeMeet" class="col col-form-label">
              {{'ROOM.FORM.TYPE_MEET' | translate}}
            </label>
            <div class="col-sm-9 col-lg-8">
              <ng-select [items]="typesMeets"
                [multiple]="true"
                class="form-control form-control-sm"
                bindLabel="name"
                bindValue="value"
                formControlName="typeMeetId"
              >
                <ng-template ng-header-tmp let-items="items">
                  <span class="ng-value-label pointer d-flex" (click)="selectAllTypesMeets()">
                    {{'ROOM.FORM.OPTION_ALL' | translate}}
                  </span>
                </ng-template>

                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label ps-2">{{item.name}}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="mb-2 row align-items-center">
            <label class="col col-form-label" for="photoFile">
              {{'ROOM.FORM.PHOTO' | translate}}
            </label>
            <div class="col-sm-9 col-lg-8">
              <input
                class="form-control"
                type="text"
                readonly
                (click)="photoFile.value = ''; photoFile.click()"
                [value]="photoFileUrl"
                placeholder="/jpg">
              <input hidden type="file" accept="image/jpeg" id="photoFile" #photoFile (change)="changePhoto($event)">
            </div>
          </div>

          <div class="mb-2 row align-items-center">
            <label class="col col-form-label">
              {{'ROOM.FORM.KAMA_VISIBILITY' | translate}}
            </label>
            <div class="col-sm-9 col-lg-8">
              <div class="container-list-controls container-list-controls-row">
                <div *ngFor="let item of typesVisibilities" class="form-check">
                  <input [id]="item.value" [value]="item.value" formControlName="visibilityId"
                    class="form-check-input" type="radio" name="visibilityId">
                  <label class="form-check-label" [for]="item.value">
                    {{item.name}}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-2 row align-items-center">
            <div class="col">
              <div class="container-list-controls container-list-controls-column">
                <div *ngFor="let item of notificationsKamas" class="form-check">
                  <input [value]="item.value" formControlName="notificationId"
                    class="form-check-input" type="radio" name="notificationId">
                  <label class="form-check-label">
                    {{item.name}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 m-auto">
          <div class="media">
            <ng-container *ngIf="photoFileUrl">
              <header>
                {{photoFileUrl.split('/') | slice: -1}}
                <span class="media-close" (click)="deletePhotoFile()">x</span>
              </header>
              <img [src]="photoFileUrl + '?t='+ randomPhotoUrl" alt="photoFile">
            </ng-container>
            <img *ngIf="photoFileUrl === ''" src="assets/images/image.webp" alt="photoFile">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="preference" formControlName="preference"
              [ngClass]="{'is-invalid': formControls['preference'].getError('required') && formSubmitted}">
            <label class="form-check-label pointer" for="preference">
              {{'ROOM.FORM.PREFERENCES' | translate}}
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="termsUso" formControlName="termsUso"
              [ngClass]="{'is-invalid': formControls['termsUso'].getError('required') && formSubmitted}">
            <label class="form-check-label pointer" for="termsUso">
              <a class="link" href="{{'ROOM.FORM.CONDITIONS_USE_LINK' | translate}}" target="_blank">
                {{'ROOM.FORM.CONDITIONS_USE' | translate}}
              </a>
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-flat-button class="btn-outline" type="submit" form="form">
    {{'ROOM.FORM.OPEN_KAMA' | translate}}
  </button>

</div>