<div class="sidenav">
  <button mat-button>
    {{'MENU.ABOUT' | translate}}
  </button>

  <button *ngIf="!authenticationService.isLoggedIn" mat-button (click)="openSignInDialog()">
    {{'AUTHENTICATION.LOGIN' | translate}}
  </button>

  <button *ngIf="!authenticationService.isLoggedIn" mat-button (click)="openRegisterDialog()">
    {{'MENU.REGISTER' | translate}}
  </button>

  <button mat-button>{{'TMPO.GET_TMPO' | translate}}</button>
  <button mat-button>{{'STORE.STORE' | translate}}</button>

  <button mat-button [matMenuTriggerFor]="menuLanguagesDefault">
    {{'LANGUAGE' | translate}}
  </button>
  <mat-menu #menuLanguagesDefault="matMenu" class="accent">
    <button *ngFor="let language of translateService.get('SUPPORTED_LANGUAGES') | async"
      (click)="translateService.use(language.LANG)" mat-menu-item>
      {{language.LANG_TEXT}}
    </button>
  </mat-menu>
</div>

