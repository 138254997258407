import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// ANGULAR MATERIAL
import { MatMenuTrigger } from '@angular/material/menu';
// MODELS
import { User } from '@models/authentication/User';
import { ResponseApi } from '@models/response-api/ResponseApi';
import { CatalogBase } from '@models/catalogs/CatalogBase';
// CONFIG
import { TYPE_USER } from '@constants/TypeUser';
import { TYPE_QUERY } from '@app/core/constants/type-query/TypeQuery';

@Component({
  selector: 'app-menu-kama',
  templateUrl: './menu-kama.component.html',
  styleUrls: ['./menu-kama.component.scss']
})
export class MenuKamaComponent implements OnInit {
  @Input() typesKamas: CatalogBase[] = [];

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  navigate(typeKama: CatalogBase) {
    this.router.navigate(['/dev/kamas'], { queryParams: { query: typeKama.id, type: TYPE_QUERY.KAMA } });
  }
}
