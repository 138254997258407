import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  supportedLanguages = ['es', 'en'];
  defaultLanguage = 'en';

  constructor(private translateService: TranslateService) {
    this.translateService.addLangs(this.supportedLanguages);
  }

  configureLanguageInitial() {
    const browserLang = this.translateService.getBrowserLang();
    if (this.supportedLanguages.includes(browserLang!)) {
      this.translateService.setDefaultLang(browserLang!);
      this.translateService.use(browserLang!);
    } else {
      this.translateService.setDefaultLang(this.defaultLanguage);
      this.translateService.use(this.defaultLanguage);
    }
  }

  get getCurrentLanguage() {
    return this.translateService.currentLang;
  }
}
