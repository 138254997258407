<mat-toolbar mat-dialog-title color="accent" class="toolbar-header--dialog">
  <span class="ma-4">{{title}}</span>

  <div right>
    <button
      *ngIf="drag"
      type="button"
      mat-icon-button
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle
    >
      <mat-icon>drag_indicator</mat-icon>
    </button>

    <button type="button" mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>
