<mat-icon class="btn-close-dialog" (click)="closeDialog()">close</mat-icon>
<div class="wrapper">
  <h2 class="display-2 text-center text-light">
    <ng-container *ngIf="typeUser === TYPE_USER.USER">
      {{'ACCOUNT.HEADER_CREATE_ACCOUNT' | translate}}
    </ng-container>
    <ng-container *ngIf="typeUser === TYPE_USER.MODEL">
      {{'ACCOUNT.HEADER_CREATE_MODEL_ACCOUNT' | translate}}
    </ng-container>
  </h2>
  <h4 class="warning-age display-4 text-light">
    {{'ACCOUNT.AGE_WARNING' | translate}}
  </h4>
  <div class="profile-content" mat-dialog-content>
    <form [formGroup]="form" id="form" #ngForm class="form-sign-up" (ngSubmit)="initTransaction($event)">
      <div class="mb-2 row">
        <label for="nickname" class="col col-form-label">
          {{'ACCOUNT.NICKNAME' | translate}}
        </label>
        <div class="col-sm-9">
          <input type="text" formControlName="nickname" class="form-control form-control-sm" id="nickname"
            [ngClass]="{'is-invalid': formControls['nickname'].getError('required') && formSubmitted}">
        </div>
      </div>

      <div class="mb-2 row">
        <label for="firstName" class="col col-form-label">
          {{'ACCOUNT.FIRST_NAME' | translate}}
        </label>
        <div class="col-sm-9">
          <input type="text" formControlName="firstName" class="form-control form-control-sm" id="firstName"
            [ngClass]="{'is-invalid': formControls['firstName'].getError('required') && formSubmitted}">
        </div>
      </div>

      <div class="mb-2 row">
        <label for="secondName" class="col col-form-label">
          {{'ACCOUNT.SECOND_NAME' | translate}}
        </label>
        <div class="col-sm-9">
          <input type="text" formControlName="secondName" class="form-control form-control-sm" id="secondName"
            [ngClass]="{'is-invalid': formControls['secondName'].getError('required') && formSubmitted}">
        </div>
      </div>

      <div class=" mb-2 row">
        <label for="password" class="col col-form-label">
          {{'ACCOUNT.PASSWORD' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="input-group input-group-sm">
            <input [type]="hide ? 'password' : 'text'" formControlName="password" class="form-control form-control-sm"
              id="password" [ngClass]="{'is-invalid': formControls['password'].getError('required') && formSubmitted}">
            <div class="input-group-text template-bg-primary pointer" (click)="hide = !hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-2 row">
        <label for="email" class="col col-form-label">
          {{'ACCOUNT.EMAIL' | translate}}
        </label>
        <div class="col-sm-9">
          <input type="email" formControlName="email" class="form-control form-control-sm" id="email"
            [ngClass]="{'is-invalid': (formControls['email'].getError('required') || formControls['email'].getError('email')) && formSubmitted}">
        </div>
      </div>

      <div class="mb-2 row">
        <label for="birthday" class="col col-form-label">
          {{'ACCOUNT.DATE_OF_BIRTHDAY' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="input-group input-group-sm">
            <input type="text" formControlName="birthday" class="form-control form-control-sm" id="birthday"
              [max]="ageMax" [readonly]="true" [matDatepicker]="picker" (focus)="picker.open()"
              [ngClass]="{'is-invalid': formControls['birthday'].getError('required') && formSubmitted}">

            <div class="input-group-text template-bg-primary pointer" (click)="picker.open()">
              <mat-icon>event</mat-icon>
            </div>
          </div>
          <mat-datepicker startView="multi-year" #picker></mat-datepicker>
        </div>
      </div>

      <div class="mb-2 row">
        <label class="col col-form-label">
          {{'ACCOUNT.SEX' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="container-list-controls container-list-controls-row">
            <div *ngFor="let gender of genders" class="form-check">
              <input [id]="gender.name" [value]="gender.value" formControlName="genderId"
                [ngClass]="{'is-invalid': formControls['genderId'].getError('required') && formSubmitted}"
                class="form-check-input" type="radio" name="genderId">
              <label class="form-check-label pointer" [for]="gender.name">
                {{gender.name}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="mb-2 row align-items-center">
        <label class="col col-form-label">
          {{'ACCOUNT.TYPE_USER' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="container-list-controls container-list-controls-row">
            <div *ngFor="let typeUser of typesUsers" class="form-check">
              <input [id]="typeUser.name" [value]="typeUser.value" formControlName="typeUserId"
                [ngClass]="{'is-invalid': formControls['typeUserId'].getError('required') && formSubmitted}"
                class="form-check-input" type="radio" name="typeUserId">
              <label class="form-check-label pointer" [for]="typeUser.name">
                {{typeUser.name}}
              </label>
            </div>
          </div>
        </div>
      </div> -->

      <div class="row">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="terms" formControlName="terms"
              [ngClass]="{'is-invalid': formControls['terms'].getError('required') && formSubmitted}">
            <label class="form-check-label pointer" for="terms">
              <a class="link" href="{{'ACCOUNT.TERMS_AND_CONDITIONS_LINK' | translate}}" target="_blank">
                {{'ACCOUNT.TERMS_AND_CONDITIONS' | translate}}
              </a>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="privacy" formControlName="privacy"
              [ngClass]="{'is-invalid': formControls['privacy'].getError('required') && formSubmitted}">
            <label class="form-check-label pointer" for="privacy">
              <a class="link" href="{{'ACCOUNT.PRIVACY_POLICY_LINK' | translate}}" target="_blank">
                {{'ACCOUNT.PRIVACY_POLICY' | translate}}
              </a>
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-flat-button color="primary" type="submit" form="form">
    {{'ACCOUNT.ACTIONS.REGISTER' | translate}}
  </button>
</div>