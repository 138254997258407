import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
// SERVICES
import { LanguageService } from '@services/language/language.service';
// MODELS
import { ResponseApi } from '@app/core/models/response-api/ResponseApi';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {
  private url = `${environment.host}/list/preference`;

  constructor(private http: HttpClient, private languageService: LanguageService) {
  }

  getAll() {
    const url = `${this.url}?language=${this.languageService.getCurrentLanguage}`;
    return this.http.get<ResponseApi>(url);
  }
}
