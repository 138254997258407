import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RXJS / LIBS
import { Subscription, forkJoin } from 'rxjs';
// SERVICES
import { AuthenticationService } from '@services/authentication/authentication.service';
import { SpinnerService } from '@services/spinner/spinner.service';
import { AlertService } from '@services/notification/alert.service';
import { TypeKamaService } from '@services/catalogs/type-kama.service';
import { TypeMeetingService } from '@services/catalogs/type-meeting.service';
import { SettingKamaService } from '@services/catalogs/setting-kama.service';
import { NotificationKamaService } from '@services/catalogs/notification-kama.service';
import { FileService } from '@services/file/file.service';
// MODELS
import { CatalogBase } from '@models/catalogs/CatalogBase';
import { Register } from '@models/authentication/Register';
import { ResponseApi } from '@models/response-api/ResponseApi';
import { FileUpload } from '@models/file/FileUpload';
import { User } from '@models/authentication/User';
// CONSTANS
import { TYPE_USER } from '@constants/TypeUser';
import { TYPE_FILE } from '@constants/TypeFile';

@Component({
  selector: 'app-kama-dialog',
  templateUrl: './kama-dialog.component.html',
  styleUrls: ['./kama-dialog.component.scss']
})
export class KamaDialogComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  form!: FormGroup;
  formSubmitted = false;
  modoVisibilidad: boolean = false;
  hide = true;
  TYPE_USER = TYPE_USER;
  typesKamas: CatalogBase[] = [];
  typesMeets: CatalogBase[] = [];
  typesVisibilities: CatalogBase[] = [];
  notificationsKamas: CatalogBase[] = [];
  currentUser?: User;
  randomPhotoUrl = this.random();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<KamaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public typeUser: number,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private typeKamaService: TypeKamaService,
    private typeMeetingService: TypeMeetingService,
    private settingKamaService: SettingKamaService,
    private notificationKamaService: NotificationKamaService,
    private fileService: FileService,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.loadUser();
    this.createForm();
    this.loadCatalogs();
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }

  get photoFileUrl() { return this.formControls['photo'].value };
  get formControls() { return this.form.controls; }
  get catalogs() {
    return [
      this.typeKamaService.getAll(),
      this.typeMeetingService.getAll(),
      this.settingKamaService.getAll(),
      this.notificationKamaService.getAll(),
    ];
  }

  closeDialog() { this.dialogRef.close(); }
  endTransaccion() { this.dialogRef.close(true); }

  createForm() {
    this.form = this.fb.group({
      typeKamaId: [[], [Validators.required]],
      nameKAMA: ['', [Validators.required]],
      typeMeetId: [[], [Validators.required]],
      photo: ['', [Validators.required]],
      visibilityId: [null, [Validators.required]],
      notificationId: [null, [Validators.required]],
      preference: [false, [Validators.requiredTrue]],
      termsUso: [false, [Validators.requiredTrue]],
    });
  }

  loadUser() {
    this.subscription.add(this.authenticationService.currentUser
      .subscribe(user => this.currentUser = user));
  }

  selectAllTypesMeets() {
    const meets = this.typesMeets.map(x => x.value);
    this.formControls['typeMeetId'].setValue(meets);
  }

  loadCatalogs() {
    this.spinner.show();
    this.subscription.add(forkJoin(this.catalogs).subscribe({
      next: (data: any[]) => {
        const [typesKamas, typesMeets, settingsKamas, notificationsKamas] = data;
        this.typesKamas = typesKamas.data as CatalogBase[];
        this.typesMeets = typesMeets.data as CatalogBase[];
        this.typesVisibilities = settingsKamas.data as CatalogBase[];
        this.notificationsKamas = notificationsKamas.data as CatalogBase[];
      },
      error: (e) => {
        this.alertService.showError(e.message);
        console.error(e.message)
      }
    }).add(() => this.spinner.hide()));
  }

  getFile($event: Event) : File {
    const target = $event.target as HTMLInputElement;
    const files = target.files as FileList;
    return files[0];
  }

  changePhoto($event: Event) {
    const data: FileUpload = {
      typeFile: TYPE_FILE.PHOTO,
      file: this.getFile($event),
      nickname: this.currentUser!.nickname,
    };
    if (data.file) {
      this.formControls['photo'].setValue('', {emitEvent: false});
      this.randomPhotoUrl = this.random();
      this.uploadFile(data, 'photo');
    }
  }

  deletePhotoFile() {
    this.formControls['photo'].setValue(null);
  }

  uploadFile(fileUpload: FileUpload, formControlName: string) {
    this.spinner.show();
    this.subscription.add(this.fileService.upload(fileUpload)
      .subscribe({
        next: (data: ResponseApi) => {
          if (data.success) {
            this.formControls[formControlName].setValue(data.data);
            this.alertService.showSmallSuccess(data.message);
          } else {
            this.alertService.showError(data.message);
          }
        },
        error: (e) => {
          console.error(e.message);
          this.alertService.showError(e.message);
        }
      }).add(() => this.spinner.hide()));
  }

  initTransaction($event: Event) {
    $event.preventDefault();
    // this.formSubmitted = true;
    // if (this.form.valid) {
    //   this.spinner.show();
    //   this.register();
    // } else {
    //   this.alertService.showErrorForm();
    // }
  }

  register() {
    const birthday = this.formControls['birthday'].value.format('YYYY-MM-DD');
    const data = {...this.form.value as Register, birthday};
    this.subscription.add(this.authenticationService.register(data)
      .subscribe({
        next: (data: ResponseApi) => {
          if (data.success) {
            this.alertService.showSuccess(data.message, 3000);
            this.endTransaccion();
          } else {
            this.alertService.showError(data.message);
          }
        },
        error: (e) => {
          const message = e?.error?.message || e.message;
          console.error(message);
          this.alertService.showError(message);
        }
      }).add(() => this.spinner.hide()));
  }

  random() {
    return new Date().getTime();
  }
}
