import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  subject = new Subject<void>();
  galleryClass = new BehaviorSubject<string>('gallery-normal');
  constructor() { }

  open() {
    this.subject.next();
  }

  changeView(view: string) {
    this.galleryClass.next(view);
  }

}
