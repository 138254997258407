import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
// MAPPERS
import { toFileUploadApi } from '@app/core/mappers/file/file-upload';
// SERVICES
import { LanguageService } from '@services/language/language.service';
// MODELS
import { FileUpload } from '@models/file/FileUpload';
import { ResponseApi } from '@models/response-api/ResponseApi';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private url = `${environment.host}/file`;

  constructor(private http: HttpClient, private languageService: LanguageService) {}

  upload(file: FileUpload) {
    const url = `${this.url}/file_upload`;
    const data = toFileUploadApi(file, this.language);
    return this.http.post<ResponseApi>(url, data);
  }

  get language(): string {
    return this.languageService.getCurrentLanguage;
  }
}
