// ModulesApp
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppRoutingModule } from '@app/app-routing.module';
import { CoreModule } from '@core/core.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ModuleTranslateLoader, IModuleTranslationOptions } from '@larscom/ngx-translate-module-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
// COMPONENTS
import { AppComponent } from '@app/app.component';
// INTERCEPTORS
import { INTERCEPTOR_PROVIDERS } from '@interceptors/interceptors';
import { DummyComponent } from './dummy/dummy.component';

export function HttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n';
  const options: IModuleTranslationOptions = {
    version: Date.now(),
    translateError: (error, path) => console.log('Oeps! an error occurred: ', { error, path }),
    modules: [
      // final url: ./assets/i18n/en.json
      { baseTranslateUrl },
      // EJEMPLO -> final url: ./assets/i18n/room/en.json
      { baseTranslateUrl, moduleName: 'account' },
      { baseTranslateUrl, moduleName: 'authentication' },
      { baseTranslateUrl, moduleName: 'form' },
      { baseTranslateUrl, moduleName: 'meet' },
      { baseTranslateUrl, moduleName: 'menu' },
      { baseTranslateUrl, moduleName: 'notification' },
      { baseTranslateUrl, moduleName: 'room' },
      { baseTranslateUrl, moduleName: 'store' },
      { baseTranslateUrl, moduleName: 'tmpo' },
    ],
  };
  return new ModuleTranslateLoader(http, options);
};


const MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  CoreModule,
  SharedModule,
  HttpClientModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    }
  }),
];

const COMPONENTS = [
  AppComponent,
  DummyComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...MODULES],
  providers: [
    INTERCEPTOR_PROVIDERS,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
// platformBrowserDynamic().bootstrapModule(AppModule);