export const ICONS_MEET = [
  {
    path: '../assets/meet/icon-ear.png',
    icon: 'icon-ear',
  },
  {
    path: '../assets/meet/icon-eplay.png',
    icon: 'icon-eplay',
  },
  {
    path: '../assets/meet/icon-eye.png',
    icon: 'icon-eye',
  },
  {
    path: '../assets/meet/icon-eye-show.png',
    icon: 'icon-eye-show',
  },
  {
    path: '../assets/meet/icon-fullsense.png',
    icon: 'icon-fullsense',
  },
  {
    path: '../assets/meet/icon-live.png',
    icon: 'icon-live',
  },
  {
    path: '../assets/meet/icon-live-avatar.png',
    icon: 'icon-live-avatar',
  },
  {
    path: '../assets/meet/icon-live-multi.png',
    icon: 'icon-live-multi',
  },
  {
    path: '../assets/meet/icon-live-multi-gangbang.png',
    icon: 'icon-live-multi-gangbang',
  },
];

export const ICONS_GALLERY = [
  {
    icon: 'big-grid',
    path: '../assets/table-cells-solid.svg',
  }
]


export const ICONS = [
  ...ICONS_GALLERY,
  ...ICONS_MEET,
]