<div mat-dialog-content>
  <h2 class="text-center">{{'AUTHENTICATION.LOGIN' | translate}}</h2>
  <form class="form" [formGroup]="form" id="form" novalidate (ngSubmit)="onSubmit($event)">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="form-control-block" appearance="outline">
          <mat-label class="form-label-field">{{'AUTHENTICATION.CONTROLS.USER' | translate}}</mat-label>
          <input
            matInput
            placeholder="{{'AUTHENTICATION.CONTROLS.USER_PLACEHOLDER' | translate}}"
            formControlName="nickname"
            autocomplete="none"
            autofocus>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="form-control-block" appearance="outline">
          <mat-label>{{'AUTHENTICATION.CONTROLS.PASSWORD' | translate}}</mat-label>
          <input
            matInput
            autocomplete="none"
            placeholder="{{'AUTHENTICATION.CONTROLS.PASSWORD_PLACEHOLDER' | translate}}"
            placeholder="Ingrese su contraseña"
            [type]="type"
            formControlName="password"
          >
          <button
            mat-icon-button
            matSuffix
            [title]="hide ? 'Mostrar' : 'Ocultar'"
            (click)="toggleVisibility($event)">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions align="center">
  <button
    mat-flat-button
    form="form"
    type="submit"
    color="primary"
    [title]="form.invalid ? 'Completa los campos': 'Iniciar sesión'">
    {{'AUTHENTICATION.ACTIONS.LOGIN' | translate}}
  </button>
</div>