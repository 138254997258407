import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RXJS / LIBS
import { Subscription, forkJoin } from 'rxjs';
import * as moment from 'moment';
// SERVICES
import { AuthenticationService } from '@services/authentication/authentication.service';
import { SpinnerService } from '@services/spinner/spinner.service';
import { AlertService } from '@services/notification/alert.service';
import { GenderService } from '@services/catalogs/gender.service';
import { LanguageService } from '@services/catalogs/language.service';
import { BodyService } from '@services/catalogs/body.service';
import { BustService } from '@services/catalogs/bust.service';
import { PenisService } from '@services/catalogs/penis.service';
import { AssService } from '@services/catalogs/ass.service';
import { RaceService } from '@services/catalogs/race.service';
import { HairService } from '@services/catalogs/hair.service';
import { LookService } from '@services/catalogs/look.service';
import { RegionService } from '@services/catalogs/region.service';
import { FileService } from '@services/file/file.service';
// MODELS
import { CatalogBase } from '@models/catalogs/CatalogBase';
import { ResponseApi } from '@models/response-api/ResponseApi';
import { Model } from '@models/model/Model';
import { FileUpload } from '@models/file/FileUpload';
import { User } from '@models/authentication/User';
// CONSTANS
import { TYPE_FILE } from '@constants/TypeFile';
@Component({
  selector: 'app-profile-model-information-dialog',
  templateUrl: './profile-model-information-dialog.component.html',
  styleUrls: ['./profile-model-information-dialog.component.scss']
})
export class ProfileInformationModelDialogComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  form!: FormGroup;
  formSubmitted = false;
  modoVisibilidad: boolean = false;
  ageMaximum = moment().add(-18, 'years').format('YYYY-MM-DD');
  ageMax = moment(this.ageMaximum);
  hide = true;
  genders: CatalogBase[] = [];
  languages: CatalogBase[] = [];
  bodyTypes: CatalogBase[] = [];
  busts: CatalogBase[] = [];
  penis: CatalogBase[] = [];
  sizeAss: CatalogBase[] = [];
  races: CatalogBase[] = [];
  hairs: CatalogBase[] = [];
  looks: CatalogBase[] = [];
  regions: CatalogBase[] = [];
  model!: Model;
  currentUser?: User;
  randomVideoUrl = this.random();
  randomPhotoUrl = this.random();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ProfileInformationModelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private genderService: GenderService,
    private languageService: LanguageService,
    private bodyService: BodyService,
    private bustService: BustService,
    private penisService: PenisService,
    private assService: AssService,
    private raceService: RaceService,
    private hairService: HairService,
    private lookService: LookService,
    private regionService: RegionService,
    private fileService: FileService,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.loadUser();
    this.createForm();
    this.loadCatalogs();
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }

  get formControls() { return this.form.controls; }
  get dniFileUrl() { return this.formControls['dni'].value };
  get photoFileUrl() { return this.formControls['photo'].value };
  get videoFileUrl() { return this.formControls['video'].value };

  get catalogs() {
    return [
      this.genderService.getAll(),
      this.languageService.getAll(),
      this.bodyService.getAll(),
      this.bustService.getAll(),
      this.penisService.getAll(),
      this.assService.getAll(),
      this.raceService.getAll(),
      this.hairService.getAll(),
      this.lookService.getAll(),
      this.regionService.getAll(),
    ];
  }

  closeDialog() { this.dialogRef.close(); }
  endTransaccion() { this.dialogRef.close(true); }

  createForm() {
    this.form = this.fb.group({
      nickname: [''],
      genderId: [null],
      birthday: [{ value: '', disabled: true}],
      languages: new FormArray([]),
      bodyTypeId: [null],
      bustId: [null],
      penisId: [null],
      sizeAssId: [null],
      raceId: [null],
      hairId: [null],
      lookId: [null],
      regionId: [null],
      blockCountry: [null],
      dni: [''],
      photo: [''],
      video: [''],
      registryTeam: [null],
      priceAjust: ['', { Validators: [Validators.min(0)], updateOn: 'blur'}],
      paymentMethod: [null],
    });
  }

  get languagesIdFormArray() {
    return this.form.controls['languages'] as FormArray;
  }

  get toCompleteInfo() { return this.model?.toCompleteInfo }

  loadUser() {
    this.subscription.add(this.authenticationService.currentUser
      .subscribe(user => this.currentUser = user));
  }

  getProfile() {
    this.spinner.show();
    this.subscription.add(this.authenticationService.getProfileModel(this.currentUser!.nickname)
    .subscribe({
      next: (data: ResponseApi) => {
        this.model = data.data as Model;
        this.loadData();
      },
      error: (e) => {
        console.error(e.message);
        this.alertService.showError(e.message);
      }
    }).add(() => {
      this.spinner.hide();
      this.onChangesForm();
    }));
  }

  loadData() {
    const model = {...this.model} as any;
    delete model.toCompleteInfo;
    console.log(model);
    this.languages.forEach((x, i) => this.languagesIdFormArray.at(i).setValue(model.languages?.includes(x.value)));
    this.form.setValue({
      ...model,
      languages: this.form.controls['languages'].value
    }, {emitEvent: false});
    console.log(this.form);
  }

  loadCatalogs() {
    this.spinner.show();
    this.subscription.add(forkJoin(this.catalogs).subscribe({
      next: (data: any[]) => {
        this.genders = data[0].data as CatalogBase[];
        this.languages = data[1].data as CatalogBase[];
        this.bodyTypes = data[2].data as CatalogBase[];
        this.busts = data[3].data as CatalogBase[];
        this.penis = data[4].data as CatalogBase[];
        this.sizeAss = data[5].data as CatalogBase[];
        this.races = data[6].data as CatalogBase[];
        this.hairs = data[7].data as CatalogBase[];
        this.looks = data[8].data as CatalogBase[];
        this.regions = data[9].data as CatalogBase[];
        this.languages.forEach(x => this.languagesIdFormArray.push(new FormControl(false)), {emitEvent: false});
        this.getProfile();
      },
      error: (e) => {
        this.alertService.showError(e.message);
        console.error(e.message);
      }
    }).add(() => this.spinner.hide()));
  }

  onChangesForm() {
    this.subscription.add(this.form.valueChanges.subscribe(() => {
      const languages = this.languages.map((x, i) => this.languagesIdFormArray.value[i] ? x.value : null).filter(x => x);
      this.updateProfileModel();
    }));
  }

  updateProfileModel() {
    this.spinner.show();
    const languages = this.languages.map((x, i) => this.languagesIdFormArray.value[i] ? x.value : null).filter(x => x);
    const data: Model = {...this.form.value, languages: languages};
    this.subscription.add(this.authenticationService.updateProfileModel(data)
      .subscribe({
        next: (data: ResponseApi) => {
          if (data.success) {
            this.alertService.showSmallSuccess(data.message);
            const model = data.data as Model;
            model.toCompleteInfo && !this.model.toCompleteInfo && this.authenticationService.applyCompleteInfo();
            this.model = data.data as Model;
          } else {
            this.alertService.showError(data.message);
          }
        },
        error: (e) => {
          console.error(e.message);
          this.alertService.showError(e.message);
        }
      }).add(() => this.spinner.hide()));
  }

  uploadFile(fileUpload: FileUpload, formControlName: string) {
    this.spinner.show();
    this.subscription.add(this.fileService.upload(fileUpload)
      .subscribe({
        next: (data: ResponseApi) => {
          if (data.success) {
            this.formControls[formControlName].setValue(data.data);
            this.alertService.showSmallSuccess(data.message);
          } else {
            this.alertService.showError(data.message);
          }
        },
        error: (e) => {
          console.error(e.message);
          this.alertService.showError(e.message);
        }
      }).add(() => this.spinner.hide()));
  }

  getFile($event: Event) : File {
    const target = $event.target as HTMLInputElement;
    const files = target.files as FileList;
    return files[0];
  }

  changeDni($event: Event) {
    const data: FileUpload = {
      typeFile: TYPE_FILE.DNI,
      file: this.getFile($event),
      nickname: this.currentUser!.nickname,
    };

    if (data.file) {
      this.formControls['dni'].setValue('', {emitEvent: false});
      this.uploadFile(data, 'dni')
    };
  }

  deleteVideoDni() {
    this.formControls['dni'].setValue(null);
  }

  changePhoto($event: Event) {
    const data: FileUpload = {
      typeFile: TYPE_FILE.PHOTO,
      file: this.getFile($event),
      nickname: this.currentUser!.nickname,
    };
    if (data.file) {
      this.formControls['photo'].setValue('', {emitEvent: false});
      this.randomPhotoUrl = this.random();
      this.uploadFile(data, 'photo');
    }
  }

  deletePhotoFile() {
    this.formControls['photo'].setValue(null);
  }

  changeVideo($event: Event) {
    const data: FileUpload = {
      typeFile: TYPE_FILE.VIDEO,
      file: this.getFile($event),
      nickname: this.currentUser!.nickname,
    };

    if (data.file) {
      this.formControls['video'].setValue('', {emitEvent: false});
      this.randomVideoUrl = this.random();
      this.uploadFile(data, 'video');
    }
  }

  deleteVideoFile() {
    this.formControls['video'].setValue(null);
  }

  random() {
    return new Date().getTime();
  }
}
