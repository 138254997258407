import { FileUpload, FileUploadApi } from '@models/file/FileUpload';

export const toFileUploadApi = (x: FileUpload, langauge: string): FormData => {
  const data =  new FormData();
  data.append('nickname', x.nickname);
  data.append('type_file', x.typeFile);
  data.append('archive', x.file);
  data.append('language_id', langauge);
  return data;
};
