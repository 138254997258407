import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ICONS } from '@constants/Icons';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ){

    ICONS.forEach(x => {
      this.matIconRegistry.addSvgIcon(x.icon, this.domSanitizer.bypassSecurityTrustResourceUrl(x.path));
    })
  }

  ngOnInit() {
  }

}