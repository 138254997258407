import { UserApi, User } from '@models/authentication/User';

export const toLogin = (x: UserApi): User => ({
  token: x.token,
  refreshToken: x.refreshToken,
  nickname: x.data_user.nickname,
  validationEmail: x.data_user.validation_email === 1,
  genderId: x.data_user.gender_id,
  toCompleteInfo: x.data_user.to_complete_info === 1,
  typeUserId: x.data_user.type_user_id,
});