<mat-icon class="close-dialog" (click)="closeDialog()">close</mat-icon>

<div class="profile-content px-4" mat-dialog-content>
  <form [formGroup]="form" id="form" (ngSubmit)="saveChanges()">
    <div class="row">
      <label for="nickname" class="form-label text-center">
        <h2 class="text-white text-center">{{'AUTHENTICATION.RESET_EMAIL.TITLE' | translate}}</h2>
      </label>
      <input type="text" formControlName="email" class="form-control form-control-sm" id="email"
        [ngClass]="{'is-invalid': invalidEmail}">
        <div class="invalid-feedback text-light" *ngIf="invalidEmail">
          <ng-container *ngIf="form.controls['email'].getError('email')">
            {{'AUTHENTICATION.RESET_EMAIL.EMAIL_ERROR' | translate}}
          </ng-container>
          <ng-container *ngIf="form.controls['email'].getError('required')">
            {{'AUTHENTICATION.RESET_EMAIL.EMAIL_REQUIRED' | translate}}
          </ng-container>
        </div>
    </div>
  </form>
</div>

<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-button class="text-white" (click)="closeDialog()">
    {{'FORM.ACTIONS.CANCEL' | translate}}
  </button>

  <button mat-flat-button color="primary" type="submit" form="form">
    {{'FORM.ACTIONS.CONFIRM' | translate}}
  </button>
</div>