import { Component, OnInit, DoCheck, OnDestroy, ViewChild, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, ROUTES, Routes } from '@angular/router';
// ANGULAR MATERIAL
import { MatMenuTrigger } from '@angular/material/menu';
import { DateAdapter } from '@angular/material/core';
// RXJS
import { Subscription, filter } from 'rxjs';
// ANGULAR MATERIAL
import { MatDialog } from '@angular/material/dialog';
// COMPONENTS
import { SignInComponent } from '@shared/components/sign-in/sign-in.component';
import { SignUpDialogComponent } from '../sign-up-dialog/sign-up-dialog.component';
import { ProfileInformationDialogComponent } from '../profile-information-dialog/profile-information-dialog.component';
import { ProfileInformationModelDialogComponent } from '../profile-model-information-dialog/profile-model-information-dialog.component';
import { ChangePasswordDialogComponent } from '@shared/components/change-password-dialog/change-password-dialog.component';
import { ChangeEmailDialogComponent } from '@shared/components/change-email-dialog/change-email-dialog.component';
import { KamaDialogComponent } from '../../../pages/kama-dialog/kama-dialog.component';
// SERVICES
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SpinnerService } from '@services/spinner/spinner.service';
import { AlertService } from '@services/notification/alert.service';
import { LanguageService } from '@services/language/language.service';
import { TypeKamaService } from '@services/catalogs/type-kama.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { EventService } from '@services/events/event.service';
// MODELS
import { User } from '@models/authentication/User';
import { ResponseApi } from '@models/response-api/ResponseApi';
import { CatalogBase } from '@models/catalogs/CatalogBase';
import { QueryParams } from '@models/query-params/QueryParams';
// CONFIG
import { TYPE_USER } from '@constants/TypeUser';
import { TYPE_QUERY } from '@app/core/constants/type-query/TypeQuery';
import { APP_ROUTES } from '@app/core/constants/AppRoutes';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, DoCheck, OnDestroy {
  title = 'cyberkama';
  mount = 10885.54;
  @ViewChild('menuProfile') menuProfile!: MatMenuTrigger;
  subscription = new Subscription();
  currentUser?: User;
  typesKamas: CatalogBase[] = [];
  TYPE_USER = TYPE_USER;
  TYPE_QUERY = TYPE_QUERY;
  APP_ROUTES = APP_ROUTES;
  typeQuery: string = '';
  currentUrl = this.router.url.split('?')[0];
  galleryViewActive = '';
  @Output() openSidenav = new EventEmitter<void>();

  constructor(
    public translateService: TranslateService,
    private languageService: LanguageService,
    private adapter: DateAdapter<Date>,
    public authenticationService: AuthenticationService,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private typeKamaService: TypeKamaService,
    private dialog: MatDialog,
    public router: Router,
    private eventService: EventService,
    private route: ActivatedRoute,
  ) {
    this.languageService.configureLanguageInitial();
    this.onChangeLanguage();
    this.onChangeViewGallery();
  }

  get isRouteKamas() { return this.currentUrl === APP_ROUTES.KAMAS;}
  get isRouteMeets() { return this.currentUrl === APP_ROUTES.MEETS;}

  ngOnInit(): void {
    this.loadUser();
    this.loadTypesKamas();
    this.onChangePath();
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }

  validateInformationComplete() {
    if (this.currentUser && !this.currentUser?.toCompleteInfo) {
      this.currentUser.typeUserId === TYPE_USER.MODEL
        ? this.openProfileModelInformation()
        : this.openProfileInformation();
    }
  }

  ngDoCheck() {
    const widthButton = document.getElementById('originPanelProfile')?.offsetWidth as any;
    const elems = document.getElementsByClassName('menuProfile') as any;
    for (let i = 0; i < elems.length; i++) {
      elems[i].style.width = widthButton + 'px';
      elems[i].style.maxWidth = 'none';
    }
  }

  onChangeLanguage() {
    this.subscription.add(
      this.translateService.onLangChange
        .subscribe(({ lang }: LangChangeEvent) => {
          this.adapter.setLocale(lang);
          this.loadTypesKamas();
        }));
  }

  onChangeViewGallery() {
    this.subscription.add(this.eventService.galleryClass.subscribe(x => this.galleryViewActive = x))
  }

  onChangePath() {
    this.subscription.add(this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => this.currentUrl = event.url.split('?')[0]));
  }

  loadUser() {
    this.subscription.add(this.authenticationService.currentUser
      .subscribe(user => {
        this.currentUser = user;
        this.validateInformationComplete();
      }));
  }

  loadTypesKamas() {
    this.spinner.show();
    this.subscription.add(this.typeKamaService.getAll()
      .subscribe({
        next: (data: ResponseApi) => {
          this.typesKamas = data.data as CatalogBase[];
        },
        error: (e) => {
          this.alertService.showError(e.message);
          console.error(e.message)
        }
      }).add(() => this.spinner.hide()));
  }

  openMenuProfile() {
    this.menuProfile.openMenu();
  }

  openRegisterDialog() {
    this.dialog.open(SignUpDialogComponent, {
      disableClose: true,
      panelClass: ['template-transparent', 'template-fullwidth__mobile', 'template-fullscreen__web'],
      backdropClass: 'template-backdrop-dialog',
      autoFocus: 'dialog',
      data: this.TYPE_USER.USER,
    });
  }

  openSignInDialog() {
    this.dialog.open(SignInComponent, { maxWidth: '300px' });
  }

  openProfile() {
    this.authenticationService.isUserModel
    ? this.openProfileModelInformation()
    : this.openProfileInformation();
  }

  openProfileModelInformation() {
    this.dialog.open(ProfileInformationModelDialogComponent, {
      disableClose: true,
      panelClass: ['template-container__primary', 'template-fullwidth__mobile', 'template-fullwidth__tablet'],
      backdropClass: 'template-backdrop-dialog',
      autoFocus: 'dialog',
    });
    // if (this.currentUser?.typeUserId !== TYPE_USER.MODEL) {
  }

  openProfileInformation() {
    this.dialog.open(ProfileInformationDialogComponent, {
      disableClose: true,
      panelClass: ['template-container__primary', 'template-fullwidth__mobile', 'template-fullwidth__tablet'],
      backdropClass: 'template-backdrop-dialog',
      autoFocus: 'dialog',
    });
  }

  openCreateKamaDialog() {
    this.dialog.open(KamaDialogComponent, {
      disableClose: true,
      panelClass: ['template-transparent', 'template-fullwidth__mobile', 'template-fullscreen__web'],
      backdropClass: 'template-backdrop-dialog',
      autoFocus: 'dialog',
    });
  }

  resetPassword() {
    this.dialog.open(ChangePasswordDialogComponent, {
      disableClose: true,
      panelClass: ['template-container__primary'],
      maxWidth: '420px',
      backdropClass: 'template-backdrop-dialog',
      autoFocus: 'dialog',
    });
  }

  resetEmail() {
    this.dialog.open(ChangeEmailDialogComponent, {
      disableClose: true,
      panelClass: ['template-container__primary'],
      maxWidth: '420px',
      backdropClass: 'template-backdrop-dialog',
      autoFocus: 'dialog',
    });
  }

  navigate(typeKama: CatalogBase) {
    const queryParams: QueryParams = { query: typeKama.value, type: TYPE_QUERY.KAMA };
    this.router.navigate([APP_ROUTES.KAMAS], { queryParams: queryParams });
  }

  openMenuMeets() {
    this.eventService.open();
  }

  changeView(view: string) {
    this.eventService.changeView(view);
  }
}
