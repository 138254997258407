<mat-icon *ngIf="toCompleteInfo" class="close-dialog" (click)="closeDialog()">close</mat-icon>
<div class="wrapper">
  <h2 mat-dialog-title class="display-2 text-light title-profile">
    {{'ACCOUNT.PROFILE_INFORMATION' | translate}}
  </h2>

  <div class="profile-content" mat-dialog-content>
    <div *ngIf="model && !toCompleteInfo" class="alert alert-warning" role="alert">
      {{'ACCOUNT.WARNING_COMPLETE_INFO' | translate}}
    </div>
    <form [formGroup]="form" id="form" #ngForm class="form-sign-up">
      <div class="my-2 row">
        <label class="col col-form-label">
          {{'ACCOUNT.SEX' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="container-list-controls container-list-controls-row">
            <div *ngFor="let gender of genders" class="form-check">
              <input [id]="gender.value" [value]="gender.value" formControlName="genderId"
                class="form-check-input" type="radio" name="genderId">
              <label class="form-check-label" [for]="gender.value">
                {{gender.name}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-2 row">
        <label for="birthday" class="col col-form-label">
          {{'ACCOUNT.DATE_OF_BIRTHDAY' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="input-group input-group-sm">
            <input type="text" formControlName="birthday" class="form-control form-control-sm" id="birthday"
              [max]="ageMax" [readonly]="true" [matDatepicker]="picker" (focus)="picker.open()">
            <div class="input-group-text template-bg-primary pointer" (click)="picker.open()">
              <mat-icon>event</mat-icon>
            </div>
          </div>
          <mat-datepicker startView="multi-year" #picker></mat-datepicker>
        </div>
      </div>

      <div class="my-2 row align-items-center">
        <label class="col col-form-label">
          {{'ACCOUNT.LANGUAGE' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="container-list-controls container-list-controls-row">
            <div *ngFor="let language of languagesIdFormArray.controls; let i = index" class="form-check"
              formArrayName="languages">
              <input [id]="languages[i].name" [formControlName]="i" class="form-check-input" type="checkbox"
                [value]="languages[i].value">
              <label class="form-check-label" [for]="languages[i].name">
                {{languages[i].name}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <h4 class="text-white mt-2 mb-0">
        {{'ACCOUNT.APPEARANCE.APPEARANCE' | translate}}
      </h4>

      <div class="my-2 row">
        <label class="col col-form-label offset-1" for="body">
          {{'ACCOUNT.APPEARANCE.BODY_TYPE' | translate}}
        </label>
        <div class="col-sm-9">
          <select id="body" class="form-select" formControlName="bodyTypeId">
            <option *ngFor="let body of bodyTypes" [value]="body.value">
              {{body.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="my-2 row">
        <label class="col col-form-label offset-1" for="bust">
          {{'ACCOUNT.APPEARANCE.BUST' | translate}}
        </label>
        <div class="col-sm-9">
          <select id="bust" class="form-select" formControlName="bustId">
            <option *ngFor="let bust of busts" [value]="bust.value">
              {{bust.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="my-2 row">
        <label class="col col-form-label offset-1" for="peni">
          {{'ACCOUNT.APPEARANCE.PENI' | translate}}
        </label>
        <div class="col-sm-9">
          <select id="peni" class="form-select" formControlName="penisId">
            <option *ngFor="let peni of penis" [value]="peni.value">
              {{peni.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="my-2 row">
        <label class="col col-form-label offset-1" for="size">
          {{'ACCOUNT.APPEARANCE.SIZE_ASS' | translate}}
        </label>
        <div class="col-sm-9">
          <select id="size" class="form-select" formControlName="sizeAssId">
            <option *ngFor="let size of sizeAss" [value]="size.value">
              {{size.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="my-2 row">
        <label class="col col-form-label offset-1" for="race">
          {{'ACCOUNT.APPEARANCE.RACE' | translate}}
        </label>
        <div class="col-sm-9">
          <select id="race" class="form-select" formControlName="raceId">
            <option *ngFor="let race of races" [value]="race.value">
              {{race.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="my-2 row">
        <label class="col col-form-label offset-1" for="hair">
          {{'ACCOUNT.APPEARANCE.HAIR' | translate}}
        </label>
        <div class="col-sm-9">
          <select id="hair" class="form-select" formControlName="hairId">
            <option *ngFor="let hair of hairs" [value]="hair.value">
              {{hair.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="my-2 row">
        <label class="col col-form-label offset-1" for="look">
          {{'ACCOUNT.APPEARANCE.LOOK' | translate}}
        </label>
        <div class="col-sm-9">
          <select id="look" class="form-select" formControlName="lookId">
            <option *ngFor="let look of looks" [value]="look.value">
              {{look.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="my-2 row">
        <label class="col col-form-label offset-1" for="region">
          {{'ACCOUNT.APPEARANCE.REGION' | translate}}
        </label>
        <div class="col-sm-9">
          <select id="region" class="form-select" formControlName="regionId">
            <option *ngFor="let region of regions" [value]="region.value">
              {{region.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="my-2 row align-items-center">
        <label class="col col-form-label" for="dniFile">
          {{'ACCOUNT.DNI' | translate}}
        </label>
        <div class="col-sm-9">
          <input class="form-control" type="text" readonly (click)="dniFile.value = ''; dniFile.click()" [value]="dniFileUrl" placeholder="/pdf">
          <input hidden type="file" accept="application/pdf" id="dniFile" #dniFile (change)="changeDni($event)">
        </div>
      </div>

      <div class="my-2 row align-items-center">
        <label class="col col-form-label" for="photoFile">
          {{'ACCOUNT.PHOTO_KAMA' | translate}}
        </label>
        <div class="col-sm-9">
          <input class="form-control" type="text" readonly (click)="photoFile.value = ''; photoFile.click()" [value]="photoFileUrl" placeholder="/jpg">
          <input hidden type="file" accept="image/jpeg" id="photoFile" #photoFile (change)="changePhoto($event)">
        </div>
      </div>


      <div class="my-2 row align-items-center">
        <label class="col col-form-label" for="videoFile">
          {{'ACCOUNT.VIDEO' | translate}}
        </label>
        <div class="col-sm-9">
          <input class="form-control" type="text" readonly (click)="videoFile.value = ''; videoFile.click()" [value]="videoFileUrl" placeholder="/mp4">
          <input hidden type="file" accept="video/mp4" id="videoFile" #videoFile (change)="changeVideo($event)">
        </div>
      </div>

      <div class="my-2 row align-items-center">
        <label class="col col-form-label" for="videoFile">
          {{'ACCOUNT.PRICE_AJUST' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="input-group input-group-sm template-bg-primary">
            <span class="input-group-text template-bg-primary">$</span>
            <input class="form-control" type="text" appNumber formControlName="priceAjust">
            <span class="input-group-text template-bg-primary">TMPO/min</span>
          </div>
        </div>
      </div>

      <div class="my-2 banner-media">
        <div class="media" *ngIf="photoFileUrl">
          <header>
            {{photoFileUrl.split('/') | slice: -1}}
            <span class="media-close" (click)="deletePhotoFile()">x</span>
          </header>
          <img [src]="photoFileUrl + '?t='+ randomPhotoUrl" alt="photoFile">
        </div>

        <div class="media" *ngIf="videoFileUrl">
          <header>
            {{videoFileUrl.split('/') | slice: -1}}
            <span class="media-close" (click)="deleteVideoFile()">x</span>
          </header>
          <video [src]="videoFileUrl + '?t='+ randomVideoUrl" controls autoplay></video>
        </div>

      </div>
    </form>
  </div>
</div>
