import * as moment from 'moment';
import { Profile, ProfileApi } from '@models/authentication/Profile';

export const toProfile = (x: ProfileApi): Profile => ({
  nickname: x.nickname,
  email: x.email,
  genderId: x.gender,
  unitId: x.unit,
  notification1: x.notifications1 === 1,
  notification2: x.notifications2 === 1,
  notification3: x.notifications3 === 1,
  preferenceId: x.preference,
  birthday: moment(x.date_of_birth),
  aboutMe: x.about_me || '',
  toCompleteInfo: x.to_complete === 1,
});

export const toProfileApi = (x: Profile): ProfileApi => ({
  nickname: x.nickname,
  email: x.email,
  gender: x.genderId,
  unit: x.unitId,
  notifications1: x.notification1 ? 1 : 0,
  notifications2: x.notification2 ? 1 : 0,
  notifications3: x.notification3 ? 1 : 0,
  preference: x.preferenceId,
  date_of_birth: x.birthday.format('YYYY-MM-DD'),
  about_me: x.aboutMe,
});
