<mat-icon class="close-dialog" (click)="closeDialog()">close</mat-icon>

<div class="profile-content px-4" mat-dialog-content>
  <form [formGroup]="form" id="form" (ngSubmit)="saveChanges()">
    <div class="row">
      <label for="nickname" class="form-label text-center">
        <h2 class="text-white text-center">{{'AUTHENTICATION.RESET_PASSWORD.TITLE' | translate}}</h2>
      </label>
      <div class="input-group input-group-sm">
        <input [type]="hide ? 'password' : 'text'" formControlName="password" class="form-control form-control-sm"
          id="password"
          [ngClass]="{'is-invalid': invalidPassword}">
          <div class="input-group-text template-bg-primary pointer" (click)="hide = !hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </div>
        <div class="invalid-feedback text-light" *ngIf="invalidPassword">
          {{'AUTHENTICATION.RESET_PASSWORD.PASSWORD_ERROR' | translate}}
        </div>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-button class="text-white" (click)="closeDialog()">
    {{'FORM.ACTIONS.CANCEL' | translate}}
  </button>

  <button mat-flat-button color="primary" type="submit" form="form">
    {{'FORM.ACTIONS.CONFIRM' | translate}}
  </button>
</div>