import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RXJS / LIBS
import { Subscription, forkJoin } from 'rxjs';
import * as moment from 'moment';
// SERVICES
import { AuthenticationService } from '@services/authentication/authentication.service';
import { SpinnerService } from '@services/spinner/spinner.service';
import { AlertService } from '@services/notification/alert.service';
import { GenderService } from '@services/catalogs/gender.service';
import { PreferenceService } from '@services/catalogs/preference.service';
import { UnitService } from '@services/catalogs/unit.service';
import { NotificationService } from '@services/catalogs/notification.service';
// MODELS
import { CatalogBase } from '@models/catalogs/CatalogBase';
import { ResponseApi } from '@models/response-api/ResponseApi';
import { Profile } from '@app/core/models/authentication/Profile';
import { User } from '@models/authentication/User';

@Component({
  selector: 'app-profile-information-dialog',
  templateUrl: './profile-information-dialog.component.html',
  styleUrls: ['./profile-information-dialog.component.scss']
})
export class ProfileInformationDialogComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  form!: FormGroup;
  formSubmitted = false;
  modoVisibilidad: boolean = false;
  ageMaximum = moment().add(-18, 'years').format('YYYY-MM-DD');
  ageMax = moment(this.ageMaximum);
  hide = true;
  genders: CatalogBase[] = [];
  units: CatalogBase[] = [];
  notifications: CatalogBase[] = [];
  preferences: CatalogBase[] = [];
  profile!: Profile;
  currentUser?: User;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ProfileInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private genderService: GenderService,
    private unitService: UnitService,
    private notificationService: NotificationService,
    private preferenceService: PreferenceService,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.loadUser();
    this.createForm();
    this.getProfile();
    this.loadCatalogs();
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }

  get formControls() { return this.form.controls; }

  get catalogs() {
    return [
      this.genderService.getAll(),
      this.unitService.getAll(),
      this.notificationService.getAll(),
      this.preferenceService.getAll(),
    ];
  }

  closeDialog() { this.dialogRef.close(); }
  endTransaccion() { this.dialogRef.close(true); }

  createForm() {
    this.form = this.fb.group({
      nickname: [''],
      email:  [''],
      genderId: [null],
      unitId: [null],
      notification1: [false],
      notification2: [false],
      notification3: [false],
      preferenceId: [null],
      birthday: [''],
      aboutMe: ['', {updateOn: 'blur'}],
    });
  }

  get toCompleteInfo() { return this.profile?.toCompleteInfo }

  loadUser() {
    this.subscription.add(this.authenticationService.currentUser
      .subscribe(user => this.currentUser = user));
  }

  getProfile() {
    this.spinner.show();
    this.subscription.add(this.authenticationService.getProfile(this.currentUser!.nickname)
    .subscribe({
      next: (data: ResponseApi) => {
        this.profile = data.data as Profile;
        this.loadData();
      },
      error: (e) => {
        console.error(e.message);
        this.alertService.showError(e.message);
      }
    }).add(() => {
      this.spinner.hide();
      this.onChangesForm();
    }));
  }

  loadData() {
    const profile = {...this.profile} as any;
    delete profile.toCompleteInfo;
    this.form.setValue(profile, {emitEvent: false});
    console.log(this.form.value);
  }

  loadCatalogs() {
    this.spinner.show();
    this.subscription.add(forkJoin(this.catalogs).subscribe({
      next: (data: any[]) => {
        const [genders, units, notifications, preferences] = data;
        this.genders = genders.data as CatalogBase[];
        this.units = units.data as CatalogBase[];
        this.notifications = notifications.data as CatalogBase[];
        this.preferences = preferences.data as CatalogBase[];
      },
      error: (e) => {
        this.alertService.showError(e.message);
        console.error(e.message)
      }
    }).add(() => this.spinner.hide()));
  }

  onChangesForm() {
    this.subscription.add(this.form.valueChanges.subscribe(() => this.updateProfile()));
  }

  updateProfile() {
    this.spinner.show();
    const data = this.form.value as Profile;
    this.subscription.add(this.authenticationService.updateProfile(data)
      .subscribe({
        next: (data: ResponseApi) => {
          if (data.success) {
            this.alertService.showSmallSuccess(data.message);
            const profile = data.data as Profile;
            profile.toCompleteInfo && !this.profile.toCompleteInfo && this.authenticationService.applyCompleteInfo();
            this.profile = data.data as Profile;
          } else {
            this.alertService.showError(data.message);
          }
        },
        error: (e) => {
          console.error(e.message);
          this.alertService.showError(e.message);
        }
      }).add(() => this.spinner.hide()));
  }
}
