<mat-icon *ngIf="currentUser?.toCompleteInfo" class="close-dialog" (click)="closeDialog()">close</mat-icon>
<div class="wrapper">
  <h2 mat-dialog-title class="display-2 text-light title-profile">
    {{'ACCOUNT.PROFILE_INFORMATION' | translate}}
  </h2>

  <div class="profile-content" mat-dialog-content>
    <div *ngIf="profile && !toCompleteInfo" class="alert alert-warning" role="alert">
      {{'ACCOUNT.WARNING_COMPLETE_INFO' | translate}}
    </div>
    <form [formGroup]="form" id="form" #ngForm class="form-sign-up" >
      <div class="mb-2 row">
        <label for="nickname" class="col col-form-label">
          {{'ACCOUNT.NICKNAME' | translate}}
        </label>
        <div class="col-sm-9">
          <input type="text" readonly formControlName="nickname" class="form-control-plaintext form-control-sm text-light" id="nickname">
        </div>
      </div>

      <div class="mb-2 row">
        <label for="email" class="col col-form-label">
          {{'ACCOUNT.EMAIL' | translate}}
        </label>
        <div class="col-sm-9">
          <input type="email" readonly formControlName="email" class="form-control-plaintext form-control-sm text-light" id="email">
        </div>
      </div>

      <div class="my-2 row">
        <label class="col col-form-label">
          {{'ACCOUNT.SEX' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="container-list-controls container-list-controls-row">
            <div *ngFor="let gender of genders" class="form-check">
              <input [id]="gender.value" [value]="gender.value" formControlName="genderId"
                class="form-check-input" type="radio" name="genderId">
              <label class="form-check-label" [for]="gender.value">
                {{gender.name}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="my-2 row align-items-center">
        <label class="col col-form-label">
          {{'ACCOUNT.UNIT' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="container-list-controls container-list-controls-row">
            <div *ngFor="let unit of units" class="form-check">
              <input [id]="unit.name" [value]="unit.value" formControlName="unitId"
                class="form-check-input" type="radio" name="unitId">
              <label class="form-check-label" [for]="unit.name">
                {{unit.name}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="my-2 row align-items-center">
        <label class="col col-form-label">
          {{'ACCOUNT.MAIL_NOTIFICATIONS' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="container-list-controls container-list-controls-column">
            <div *ngFor="let notification of notifications.slice(0,1)"class="form-check">
              <input [id]="notification.name" [value]="true" formControlName="notification1" [name]="notification.name"
                class="form-check-input" type="checkbox" name="notification1">
              <label class="form-check-label" [for]="notification.name">
                {{notification.name}}
              </label>
            </div>
          </div>

          <div *ngFor="let notification of notifications.slice(1,2)" class="form-check">
            <input [id]="notification.name" [value]="true" formControlName="notification2" [name]="notification.name"
              class="form-check-input" type="checkbox" name="notification2">
            <label class="form-check-label" [for]="notification.name">
              {{notification.name}}
            </label>
          </div>

          <div *ngFor="let notification of notifications.slice(2)" class="form-check">
            <input [id]="notification.name" [value]="true" formControlName="notification3" [name]="notification.name"
              class="form-check-input" type="checkbox" name="notification3">
            <label class="form-check-label" [for]="notification.name">
              {{notification.name}}
            </label>
          </div>
        </div>
      </div>

      <div class="my-2 row align-items-center">
        <label class="col col-form-label">
          {{'ACCOUNT.SEXUAL_PREFERENCES' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="container-list-controls container-list-controls-row">
            <div *ngFor="let preference of preferences" class="form-check">
              <input [id]="preference.name" [value]="preference.value" formControlName="preferenceId"
                class="form-check-input" type="radio" name="preferenceId">
              <label class="form-check-label" [for]="preference.name">
                {{preference.name}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-2 row">
        <label for="birthday" class="col col-form-label">
          {{'ACCOUNT.DATE_OF_BIRTHDAY' | translate}}
        </label>
        <div class="col-sm-9">
          <div class="input-group input-group-sm">
            <input type="text" formControlName="birthday" class="form-control form-control-sm" id="birthday" [max]="ageMax"
              [readonly]="true" [matDatepicker]="picker" (focus)="picker.open()">
            <div class="input-group-text template-bg-primary pointer" (click)="picker.open()">
              <mat-icon>event</mat-icon>
            </div>
          </div>
          <mat-datepicker startView="multi-year" #picker></mat-datepicker>
        </div>
      </div>

      <div class="mb-2 row">
        <label for="aboutMe" class="col col-form-label">
          {{'ACCOUNT.ABOUT_ME' | translate}}
        </label>
        <div class="col-sm-9">
          <textarea rows="4" formControlName="aboutMe" class="form-control form-control-sm" id="aboutMe">
          </textarea>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- <div mat-dialog-actions align="end">
  <button mat-flat-button color="primary" type="submit" form="form">
    {{'ACCOUNT.ACTIONS.SAVE' | translate}}
  </button>
</div> -->