import { Component, OnInit } from '@angular/core';
// ANGULAR MATERIAL
import { MatDialog } from '@angular/material/dialog';
// COMPONENTS
import { SignUpDialogComponent } from '../sign-up-dialog/sign-up-dialog.component';
import { SignInComponent } from '../sign-in/sign-in.component';
// SERVICES
import { AuthenticationService } from '@services/authentication/authentication.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
// CONFIG
import { TYPE_USER } from '@constants/TypeUser';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  constructor(
    public authenticationService: AuthenticationService,
    public translateService: TranslateService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  openRegisterDialog() {
    this.dialog.open(SignUpDialogComponent, {
      disableClose: true,
      panelClass: ['template-transparent', 'template-fullwidth__mobile', 'template-fullscreen__web'],
      backdropClass: 'template-backdrop-dialog',
      autoFocus: 'dialog',
      data: TYPE_USER.USER,
    });
  }

  openSignInDialog() {
    this.dialog.open(SignInComponent, { maxWidth: '300px' });
  }

}
