import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AsyncPipe } from '@angular/common';
// PIPES
// COMPONENTS
import { ToolbarComponent } from '@shared/components/toolbar/toolbar.component';
import { SignInComponent } from '@shared/components/sign-in/sign-in.component';
import { ToolbarDialogComponent } from '@shared/components/toolbar-dialog/toolbar-dialog.component';
import { SidenavComponent } from '@shared/components/sidenav/sidenav.component';
import { SignUpDialogComponent } from './components/sign-up-dialog/sign-up-dialog.component';
import { ProfileInformationDialogComponent } from './components/profile-information-dialog/profile-information-dialog.component';
import { ProfileInformationModelDialogComponent } from './components/profile-model-information-dialog/profile-model-information-dialog.component';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import { ChangeEmailDialogComponent } from './components/change-email-dialog/change-email-dialog.component';
import { MenuKamaComponent } from '@shared/components/toolbar/menu-kama/menu-kama.component';

// DIRECTIVES
import { NumberIntegerDirective } from '@directives/number-integer.directive';
import { NumberDirective } from '@directives/number.directive';
// MODULES
import { NgxSpinnerModule } from "ngx-spinner";
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './modules/material.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';

const MODULES = [
  CommonModule,
  FormsModule,
  MaterialModule,
  RouterModule,
  ReactiveFormsModule,
  NgxSpinnerModule,
  TranslateModule,
  InfiniteScrollModule,
  NgSelectModule,
];

const COMPONENTS: any[] = [
  ToolbarComponent,
  SignInComponent,
  SignUpDialogComponent,
  ProfileInformationDialogComponent,
  ProfileInformationModelDialogComponent,
  ChangePasswordDialogComponent,
  ChangeEmailDialogComponent,
  ToolbarDialogComponent,
  SidenavComponent,
  MenuKamaComponent,
];

const PIPES: any[] = [
  // StringIsNullPipe
];

const DIRECTIVES: any[] = [
  NumberIntegerDirective,
  NumberDirective,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
  ],
  imports: [...MODULES],
  exports: [
    ...MODULES,
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
  ],
  providers: [AsyncPipe],
})
export class SharedModule { }