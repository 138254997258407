import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RXJS / LIBS
import { Subscription } from 'rxjs';
// SERVICES
import { AuthenticationService } from '@services/authentication/authentication.service';
import { SpinnerService } from '@services/spinner/spinner.service';
import { AlertService } from '@services/notification/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
// MODELS
import { User } from '@models/authentication/User';
import { ResponseApi } from '@models/response-api/ResponseApi';

@Component({
  selector: 'app-change-email-dialog',
  templateUrl: './change-email-dialog.component.html',
  styleUrls: ['./change-email-dialog.component.scss']
})
export class ChangeEmailDialogComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  form!: FormGroup;
  formSubmitted = false;
  currentUser?: User;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChangeEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public typeUser: number,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.loadUser();
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }

  closeDialog() { this.dialogRef.close(); }

  loadUser() {
    this.subscription.add(this.authenticationService.currentUser
      .subscribe(user => this.currentUser = user));
  }

  get invalidEmail() {
    return (this.form.controls['email'].getError('required') ||
      this.form.controls['email'].getError('email')) && this.formSubmitted;
  }

  saveChanges() {
    this.formSubmitted = true;
    if (this.form.valid) {
      this.changeEmail();
    }
  }

  changeEmail() {
    this.spinner.show();
    const email = this.form.controls['email'].value;
    this.subscription.add(this.authenticationService.resetEmail(this.currentUser?.nickname!, email)
      .subscribe({
        next: (data: ResponseApi) => {
          this.alertService.showSuccess(data.data, 3000);
          this.closeDialog();
        },
        error: (e: HttpErrorResponse) => {
          const message = e?.error?.message || e.message;
          console.error(message);
          this.alertService.showError(message);
        }
      }).add(() => this.spinner.hide()));
  }
}
