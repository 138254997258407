<div class="header">
  <mat-toolbar color="primary" class="toolbar-app">
    <img class="logo" src="./../assets/images/logo.png" alt="logo">

    <div class="toolbar-center group-center">
      <app-menu-kama *ngIf="isRouteKamas" [typesKamas]="typesKamas"></app-menu-kama>
      <button *ngIf="isRouteMeets" mat-icon-button (click)="openMenuMeets()">
        <mat-icon>menu</mat-icon>
      </button>

      <div class="wrapper-control__black wrapper-control__search">
        <mat-form-field appearance="outline" class="form-control-block form-control__search form-control__black" floatLabel="always">
          <input matInput #input placeholder="{{'MENU.CONTROLS.SEARCH_PLACEHOLDER' | translate}}">
          <mat-icon matPrefix class="prefix-search">search</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <button class="btn-sidenav" mat-icon-button (click)="openSidenav.emit()">
      <mat-icon>menu_open</mat-icon>
    </button>

    <div class="toolbar-right group-right">
      <button mat-button>{{'TMPO.GET_TMPO' | translate}}</button>
      <button mat-button>{{'STORE.STORE' | translate}}</button>
      <button
        *ngIf="!authenticationService.isLoggedIn"
        mat-button
        (click)="openRegisterDialog()">
        {{'MENU.JOIN' | translate}}
      </button>

      <button
        *ngIf="!authenticationService.isLoggedIn"
        mat-button
        (click)="openSignInDialog()">
        {{'AUTHENTICATION.LOGIN' | translate}}
      </button>

      <button mat-icon-button [matMenuTriggerFor]="menuLanguagesDefault">
        <mat-icon>language</mat-icon>
      </button>

      <mat-menu #menuLanguagesDefault="matMenu" class="primary">
        <button *ngFor="let language of translateService.get('SUPPORTED_LANGUAGES') | async"
          (click)="translateService.use(language.LANG)" mat-menu-item>
          {{language.LANG_TEXT}}
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-toolbar color="accent" class="toolbar-secundary-app">
    <div class="toolbar-secundary-left">
      <!-- <button mat-button>KAMApé</button> -->
    </div>

    <div class="toolbar-secundary-center">
      <button mat-button [routerLink]="['/dev/kamas']" routerLinkActive="active-link">
        {{'ROOM.KAMAS' | translate}}
      </button>
      <button *ngIf="authenticationService.isLoggedIn" mat-button (click)="openCreateKamaDialog()">
        {{'ROOM.CREATE_KAMA' | translate}}
      </button>
      <button *ngIf="authenticationService.isUserNormal" mat-button [routerLink]="['/dev/meets']" routerLinkActive="active-link">
        {{'ROOM.MEETING' | translate}}
      </button>
    </div>

    <div class="toolbar-secundary-right">
      <div
        *ngIf="authenticationService.isLoggedIn"
        id="originPanelProfile"
        class="group-actions"
        [class.panelProfileSelected]="menuProfile.menuOpen">
        <div class="panel-user__back" [matMenuTriggerFor]="languageMenu" #menuProfile="matMenuTrigger">
        </div>

        <div class="panel-user">
          <div class="panel-user__img">
            <mat-icon>person_filled</mat-icon>
          </div>
          <div class="panel-user__detail">
            <span class="name">{{currentUser?.nickname}}
              <mat-icon (click)="openMenuProfile()">expand_more</mat-icon>
            </span>
            <span class="mount">
              ${{mount}}&nbsp;TMPO
            </span>
          </div>

          <mat-menu #languageMenu="matMenu" class="menuProfile primary">
            <button *ngIf="authenticationService.isLoggedIn" mat-menu-item (click)="openProfile()">
              <mat-icon>person</mat-icon>
              {{'MENU.PROFILE_SUBMENU.PROFILE_INFORMATION' | translate}}
            </button>

            <button mat-menu-item (click)="resetPassword()">
              <mat-icon>lock</mat-icon>
              {{'MENU.PROFILE_SUBMENU.CHANGE_PASSWORD' | translate}}
            </button>

            <button mat-menu-item (click)="resetEmail()">
              <mat-icon>email</mat-icon>
              {{'MENU.PROFILE_SUBMENU.CHANGE_EMAIL' | translate}}
            </button>

            <button mat-menu-item [matMenuTriggerFor]="menuLanguages">
              <mat-icon>language</mat-icon>
              {{'LANGUAGE' | translate}}
            </button>

            <button mat-menu-item (click)="authenticationService.logout()">
              <mat-icon>logout</mat-icon>
              {{'AUTHENTICATION.LOGOUT' | translate}}
            </button>
          </mat-menu>

          <mat-menu #menuLanguages="matMenu" hasBackdrop="false" class="primary">
            <button *ngFor="let language of translateService.get('SUPPORTED_LANGUAGES') | async"
              (click)="translateService.use(language.LANG)" mat-menu-item>
              {{language.LANG_TEXT}}
            </button>
          </mat-menu>
        </div>

        <div class="group-actions__align">
          <button mat-stroked-button matTooltip="{{'MENU.MESSAGE' | translate}}" color="primary">
            <mat-icon>chat</mat-icon>
          </button>
          <button mat-stroked-button matTooltip="{{'MENU.NOTIFICATION' | translate}}" color="primary">
            <mat-icon>notifications</mat-icon>
          </button>

          <button mat-stroked-button matTooltip="{{'MENU.FAVORITE' | translate}}" color="primary">
            <mat-icon>favorite</mat-icon>
          </button>
        </div>
      </div>

      <div class="group-align group-align-gallery" *ngIf="isRouteKamas">
        <button mat-icon-button class="grid-icon" [ngClass]="galleryViewActive === 'gallery-short' ? 'grid-icon-active' : ''" (click)="changeView('gallery-short')">
          <mat-icon svgIcon="big-grid" class="svg-grid"></mat-icon>
        </button>
        <button mat-icon-button class="grid-icon" [ngClass]="galleryViewActive === 'gallery-normal' ? 'grid-icon-active' : ''" (click)="changeView('gallery-normal')">
          <mat-icon>apps</mat-icon>
        </button>
        <button mat-icon-button class="grid-icon" [ngClass]="galleryViewActive === 'gallery-big' ? 'grid-icon-active' : ''" (click)="changeView('gallery-big')">
          <mat-icon>grid_view</mat-icon>
        </button>
      </div>

    </div>
  </mat-toolbar>
</div>