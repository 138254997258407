import * as moment from 'moment';
import { Model, ModelApi } from '@app/core/models/model/Model';

export const toModel = (x: ModelApi): Model => ({
  nickname: x.nickname,
  genderId: x.gender,
  birthday: moment(x.date_of_birth),
  languages: x.language || [],
  bodyTypeId: x.body_type,
  bustId: x.bust,
  penisId: x.penis,
  sizeAssId: x.size_ass,
  raceId: x.race,
  hairId: x.hair,
  lookId: x.look,
  regionId: x.regions,
  blockCountry: x.block_country || [],
  dni: x.ID,
  photo: x.photo,
  video: x.video,
  registryTeam: x.registry_team,
  priceAjust: x.price_ajust,
  paymentMethod: x.payment_method,
  toCompleteInfo: x.to_complete === 1,
});

export const toModelApi = (x: Model): ModelApi => ({
  nickname: x.nickname,
  gender: x.genderId,
  date_of_birth: x.birthday?.format('YYYY-MM-DD'),
  language: x.languages,
  body_type: x.bodyTypeId,
  bust: x.bustId,
  penis: x.penisId,
  size_ass: x.sizeAssId,
  race: x.raceId,
  hair: x.hairId,
  look: x.lookId,
  regions: x.regionId,
  block_country: x.blockCountry,
  ID: x.dni,
  photo: x.photo,
  video: x.video,
  registry_team: x.registryTeam,
  price_ajust: x.priceAjust,
  payment_method: x.paymentMethod,
});