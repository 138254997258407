import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RXJS / LIBS
import { Subscription, forkJoin } from 'rxjs';
import * as moment from 'moment';
// SERVICES
import { AuthenticationService } from '@services/authentication/authentication.service';
import { SpinnerService } from '@services/spinner/spinner.service';
import { AlertService } from '@services/notification/alert.service';
import { GenderService } from '@services/catalogs/gender.service';
// MODELS
import { CatalogBase } from '@models/catalogs/CatalogBase';
import { Register } from '@models/authentication/Register';
import { ResponseApi } from '@models/response-api/ResponseApi';
// CONSTANS
import { TYPE_USER } from '@constants/TypeUser';

@Component({
  selector: 'app-sign-up-dialog',
  templateUrl: './sign-up-dialog.component.html',
  styleUrls: ['./sign-up-dialog.component.scss']
})
export class SignUpDialogComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  form!: FormGroup;
  formSubmitted = false;
  modoVisibilidad: boolean = false;
  ageMaximum = moment().add(-18, 'years').format('YYYY-MM-DD');
  ageMax = moment(this.ageMaximum);
  hide = true;
  TYPE_USER = TYPE_USER;
  genders: CatalogBase[] = [];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SignUpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public typeUser: number,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private genderService: GenderService,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.createForm();
    this.loadCatalogs();
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }

  get formControls() { return this.form.controls; }

  get catalogs() {
    return [
      this.genderService.getAll(),
    ];
  }

  closeDialog() { this.dialogRef.close(); }
  endTransaccion() { this.dialogRef.close(true); }

  createForm() {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      secondName: ['', [Validators.required]],
      nickname: ['', [Validators.required]],
      password: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      birthday: ['', [Validators.required]],
      genderId: [null, [Validators.required]],
      typeUserId: [this.typeUser, [Validators.required]],
      terms: [false, [Validators.requiredTrue]],
      privacy: [false, [Validators.requiredTrue]],
    });
  }

  loadCatalogs() {
    this.spinner.show();
    this.subscription.add(forkJoin(this.catalogs).subscribe({
      next: (data: any[]) => {
        const [genders] = data;
        this.genders = genders.data as CatalogBase[];
      },
      error: (e) => {
        this.alertService.showError(e.message);
        console.error(e.message)
      }
    }).add(() => this.spinner.hide()));
  }

  initTransaction($event: Event) {
    $event.preventDefault();
    this.formSubmitted = true;
    if (this.form.valid) {
      this.spinner.show();
      this.register();
    } else {
      this.alertService.showErrorForm();
    }
  }

  register() {
    const birthday = this.formControls['birthday'].value.format('YYYY-MM-DD');
    const data = {...this.form.value as Register, birthday};
    this.subscription.add(this.authenticationService.register(data)
      .subscribe({
        next: (data: ResponseApi) => {
          if (data.success) {
            this.alertService.showSuccess(data.message, 3000);
            this.endTransaccion();
          } else {
            this.alertService.showError(data.message);
          }
        },
        error: (e) => {
          const message = e?.error?.message || e.message;
          console.log(message);
          console.error(message);
          this.alertService.showError(message);
        }
      }).add(() => this.spinner.hide()));
  }
}
