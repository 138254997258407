import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
// LIBS
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
// SERVICES
import { LocalStorageService } from '@services/storage/local-storage.service';
import { LanguageService } from '@services/language/language.service';
// MAPPERS
import { toRegisterApi } from '@mappers/authentication/register';
import { toLogin } from '@mappers/authentication/login';
import { toProfile, toProfileApi } from '@mappers/authentication/profile';
import { toModel, toModelApi } from '@mappers/model/model';
// MODELS
import { ResponseApi } from '@models/response-api/ResponseApi';
import { UserLogin } from '@models/authentication/UserLogin';
import { User } from '@models/authentication/User';
import { Register } from '@models/authentication/Register';
import { Profile } from '@models/authentication/Profile';
import { Model } from '@models/model/Model';
// CONST
import { StorageKeys } from '@constants/StorageKeys';
import { TYPE_USER } from '@app/core/constants/TypeUser';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private url = `${environment.host}/user`;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private storageService: LocalStorageService,
    private router: Router,
    private languageService: LanguageService) {
    this.currentUserSubject = new BehaviorSubject<User>(this.getUser);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  authenticate(userLogin: UserLogin) {
    const url = `${this.url}/authenticate`;
    return this.http.post<ResponseApi>(url, {...userLogin, language_id: this.language})
    .pipe(map((x: ResponseApi) => ({...x, data: toLogin(x.data)})));
  }

  register(register: Register) {
    const url = `${this.url}/register`;
    const data = {...toRegisterApi(register), language_id: this.language};
    return this.http.post<ResponseApi>(url, data);
  }

  getProfile(nickname: string) {
    const url = `${this.url}/getProfileUser`;
    return this.http.post<ResponseApi>(url, {nickname})
    .pipe(map((x: ResponseApi) => ({...x, data: toProfile(x.data)})));
  }

  getProfileModel(nickname: string) {
    const url = `${this.url}/getProfileModel`;
    return this.http.post<ResponseApi>(url, {nickname})
    .pipe(map((x: ResponseApi) => ({...x, data: toModel(x.data)})));
  }

  updateProfile(profile: Profile) {
    const url = `${this.url}/setProfileUser`;
    const data = {...toProfileApi(profile), language_id: this.language};
    return this.http.post<ResponseApi>(url, data)
    .pipe(map((x: ResponseApi) => ({...x, data: toProfile(x.data)})));
  }

  updateProfileModel(model: Model) {
    const url = `${this.url}/setProfileModel`;
    const data = {...toModelApi(model), language_id: this.language};
    return this.http.post<ResponseApi>(url, data)
    .pipe(map((x: ResponseApi) => ({...x, data: toModel(x.data)})));
  }

  // updateFieldUser(property: string, value: any) {
  //   const user = {...this.currentUserValue, property: value } as User;
  //   this.currentUserSubject.next(user);
  //   this.storageService.storeObject(StorageKeys.UserKey, this.currentUserValue);
  // }

  resetEmail(nickname: string, email: string) {
    const url = `${this.url}/resetemail`;
    const data = {nickname, email, language_id: this.language};
    return this.http.post<ResponseApi>(url, data);
  }

  resetPassword(nickname: string, password: string) {
    const url = `${this.url}/resetpass`;
    const data = {nickname,  password, language_id: this.language};
    return this.http.post<ResponseApi>(url, data);
  }

  applyCompleteInfo() {
    this.currentUserValue.toCompleteInfo = true;
    this.storageService.storeObject(StorageKeys.UserKey, this.currentUserValue);
  }

  refreshToken() {
    const apiUrl = `${this.url}/refresh`;
    const data = { refresh: this.getRefreshToken };
    return this.http.post<ResponseApi>(apiUrl, data)
      .pipe(tap(({ data }) => {
        const { token = '' } = data || {};
        const user = { ...this.getUser, token }
        this.storageService.storeObject(StorageKeys.UserKey, user);
        this.storageService.storeItem(StorageKeys.JwtKey, token);
        this.currentUserSubject?.next(user);
      }));
  }

  // Almacena las configuraciones del usuario logeado
  doLoginUser(user: User) {
    console.log(user);
    this.currentUserSubject.next(user);
    this.storageService.storeObject(StorageKeys.UserKey, user);
    this.storageService.storeItem(StorageKeys.JwtKey, user.token);
    this.storageService.storeItem(StorageKeys.RefreshKey, user.refreshToken);
  }

  logout() {
    this.doLogoutUser();
  }

  private doLogoutUser() {
    this.storageService.remove(StorageKeys.UserKey);
    this.storageService.remove(StorageKeys.JwtKey);
    this.storageService.remove(StorageKeys.RefreshKey);
    this.router.navigate(['/dev/kamas'], { replaceUrl: true });
    this.currentUserSubject.next(null);
    // this.location.replaceState('/auth/sign-in');
    // this.router.navigate(['/sign-in'], { replaceUrl: true, skipLocationChange: true });
  }

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get isLoggedIn(): boolean {
    return !!this.currentUserValue;
  }

  get getJwtToken(): string {
    return this.storageService.getItem(StorageKeys.JwtKey);
  }

  get getRefreshToken(): string {
    return this.storageService.getItem(StorageKeys.RefreshKey);
  }

  get getUser(): User {
    return this.storageService.getObject(StorageKeys.UserKey);
  }

  get language(): string {
    return this.languageService.getCurrentLanguage;
  }

  get isUserModel() : boolean {
    return this.currentUserValue?.typeUserId === TYPE_USER.MODEL;
  }

  get isUserNormal() : boolean {
    return this.currentUserValue?.typeUserId === TYPE_USER.USER;
  }
}
