// ANGULAR
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
// RXJS
import { Observable } from 'rxjs';
// SERVICES
import { AuthenticationService } from '@services/authentication/authentication.service';
// MODELS
import { User } from '@models/authentication/User';


@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isRouteLogin = request.url.includes('/register');
    const isRouteRefresh = false;
    const isRouteFormData = false;
    let requestModified: HttpRequest<any> = request;
    const currentUser: User = this.authenticationService.currentUserValue;

    // VALIDATION OF TYPE HEADER FOR THE ROUTES
    if (isRouteLogin) {
      requestModified = this.addHeaderToRequestWithoutToken(request);
    } else if (isRouteRefresh) {
      requestModified = this.addTokenToRequest(request, currentUser.refreshToken);
    } else if (currentUser && currentUser.token) {
      if (isRouteFormData) {
        requestModified = this.addTokenToRequestFormData(request, currentUser.token);
      } else {
        requestModified = this.addTokenToRequest(request, currentUser.token);
      }
    }
    return next.handle(requestModified);
  }

  // ATTACH TOKEN AT THE REQUEST
  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Authorization': 'Bearer ' + token,
        // 'Content-Type': 'application/json'
      }
    });
  }

  private addTokenToRequestFormData(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ setHeaders: { 'Authorization': 'Bearer ' + token } });
  }

  // ATTACH HEARDER AT THE REQUEST WITHOUT TOKEN OR AUTHORIZATION
  private addHeaderToRequestWithoutToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
  }
}